<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.content.downloadManagement.headers.detail.main') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.name', {language: $t('generally.languages.' + locale)}) }}:</b-td>
                                    <b-td>
                                        <img :src="data.image" alt="Logo" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                        <b-icon icon="download" variant="orange" v-show="!picture"></b-icon>
                                        {{ name[locale] }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.description', {language: $t('generally.languages.' + locale)}) }}:</b-td>
                                    <b-td>{{ description[locale] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.type') }}:</b-td>
                                    <b-td>{{ $t('pages.content.downloadManagement.values.type.' + data.type) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.public') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.public ? 'text-success' : 'text-danger'" :icon="data.public ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.created') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.downloadManagement.form.updated') }}:</b-td>
                                    <b-td>{{ $moment(data.updatedAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col sm="12" md="6" lg="8" xl="9">
                        <h4>{{ $t('pages.content.downloadManagement.headers.detail.files') }}</h4>
                        <b-table
                            small
                            striped
                            :empty-text="$t('generally.tables.emptyText')"
                            :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                            show-empty
                            :items="data.files"
                            :fields="fields"
                        >
                            <template #cell(fileIcon)="row">
                                <font-awesome-icon :icon="getIcon(row.item.name)" style="font-size: 20px"/>
                            </template>
                            <template #cell(name)="row">
                                {{ row.item.name }}
                            </template>

                            <template #cell(size)="row">
                                {{ bytesToSize(row.item.size) }}
                            </template>
                            <template #cell(mimetype)="row">
                                <span :title="row.item.mimetype">{{ row.item.mimetype.substring(0, row.item.mimetype.length > 28 ? 28 : row.item.mimetype.length) }}<span v-if="row.item.mimetype.length > 28">...</span></span>
                            </template>
                            <template #cell(action)="row">
                                <b-button size="sm" @click="download(row.item)">
                                    <font-awesome-icon icon="download"/>
                                    {{ $t('buttons.download') }}
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <hr>
                <user-to-resource-list :data="data.users" attr="users_downloads"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {EventBus} from "../../../services/EventBus";
import downloadService from "../../../services/downloadService";
import {getClassNameForFilename} from "../../../services/font-awesome-filetypes";
import fileService from "../../../services/fileService";
import mainServices from "../../../services/mainServices";
import UserToResourceList from "../../../components/UserToResourceList";

export default {
    name: "Detail",
    components: {UserToResourceList},
    props: ["id"],
    data: () => ({
        data: {},
        fields: [],
        name: {
            de: '',
            en: ''
        },
        description: {
            de: '',
            en: ''
        },
        image: '',
        picture: '',
        locale: 'de'
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            {key: "fileIcon", label: '', tdClass: 'align-middle text-center'},
            {key: "name", label: this.$t('pages.content.downloadManagement.form.files.table.name'), tdClass: 'align-middle'},
            {key: "size", label: this.$t('pages.content.downloadManagement.form.files.table.size'), tdClass: 'align-middle'},
            {key: "mimetype", label: this.$t('pages.content.downloadManagement.form.files.table.type'), tdClass: 'align-middle'},
            {key: "label", label: this.$t('pages.content.downloadManagement.form.files.table.label'), tdClass: 'align-middle'},
            {key: "action", label: '', tdClass: 'align-middle text-right'}
        ];
        this.loadData();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.$toastr.i(this.$t('generally.syncMessage'));
            this.loadData();
        });
    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        loadData() {
            downloadService.get(this.id).then(response => {
                response.data.labels = JSON.parse(response.data.labels);
                response.data.files = response.data.files.map(file => {
                    file = JSON.parse(file);
                    let labelFind = response.data.labels.find(label => label.file === file.name);
                    file.label = labelFind ? labelFind.label : '';
                    return file;
                });
                this.name = JSON.parse(response.data.name);
                this.description = JSON.parse(response.data.description);
                this.data = response.data;
            }).catch();
        },
        download(file) {
            downloadService.getDownload(this.data.id, file['sha3-256']).then(response => {
                mainServices.downloadLink(mainServices.apiUrl() + `/downloadLinks/${response.data.data}`, file.name);
            }).catch(error => {
                this.errorHandler(error);
            });
        },
        getIcon(filename) {
            return getClassNameForFilename(filename);
        },
        bytesToSize(bytes) {
            return fileService.bytesToSize(bytes);
        }
    }
};
</script>

<style scoped>

</style>
