<template>
    <div>
        <h4>{{ $t('generally.userToResourceList.heading') }}</h4>
        <b-row>
            <b-col sm="6" md="8" xl="8" class="my-1">
                {{ $t('generally.userToResourceList.tableCount') }}: {{ totalRows }}
            </b-col>
            <b-col sm="6" md="4" xl="4" class="my-1">
                <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('generally.tables.tipToSearch')"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    stacked="md"
                    show-empty
                    striped
                    selectable
                    small
                    select-mode="multi"
                    ref="resourceTable"
                    class="bg-white"
                >

                    <template #cell(name)="row">
                        <CLink :to="{ name: 'AdminUserDetail', params: { id: row.item.id }}">{{ row.item.firstName }} {{ row.item.lastName }}</CLink>
                    </template>

                    <template #cell(expiresAt)="row">
                        <span v-if="row.item[attr]">{{ $moment(row.item[attr].expiresAt).format('DD.MM.YYYY HH:mm') }}</span>
                    </template>

                    <template #cell(createdAt)="row">
                        {{ $moment(row.item[attr].createdAt).format('DD.MM.YYYY HH:mm') }}
                    </template>

                    <template #cell(actions)="row">
                        <b-button variant="info" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')" @click="onShow(row.item)" v-b-modal.info-modal>
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                        <b-button variant="orange" v-if="row.item[attr]" class="btn-xs mb-0 ml-1" :title="$t('buttons.edit')" @click="onEdit(row.item)">
                            <font-awesome-icon icon="clock"/>
                        </b-button>
                    </template>

                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "UserToResourceList",
    props: ["data", "attr"],
    data: () => ({
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        items: []
    }),
    watch:{
        data(val){
            this.items = val ? val : [];
            this.totalRows = this.items.length;
        }
    },
    mounted() {
        this.fields = [
            {key: 'name', label: this.$t('pages.admin.user.table.resources.name'), sortable: true, sortDirection: 'asc'},
            {key: 'expiresAt', label: this.$t('pages.admin.user.table.resources.expiresAt'), sortable: true, sortDirection: 'asc'},
            {key: 'createdAt', label: this.$t('pages.admin.user.table.resources.createdAt'), sortable: true},
        ];
        this.items = this.data ? this.data : [];
        this.totalRows = this.items.length;
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>

<style scoped>

</style>
